<template>
  <b-card>
    <h4 class="mb-0">
      Edit Gaji Tetap
    </h4>
    <validation-observer ref="validUpdate">
      <b-form class="mt-1" @submit.prevent="validationForm">
        <b-row>
          <b-col md="6">
            <validation-provider #default="{ errors }" name="employee_id" rules="required">
              <b-form-group label="Pegawai" label-for="employee_id" :state="errors.length > 0 ? false : null">
                <v-select id="employee_id" v-model="dataStudent.employee_id" :reduce="(employee_id) => employee_id.id"
                  placeholder="Pegawai" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listPegawai"
                  label="name" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="Tanggal Efektif" label-for="effective_date">
              <validation-provider #default="{ errors }" name="effective_date" rules="required">
                <b-form-input id="effective_date" v-model="dataStudent.effective_date"
                  :state="errors.length > 0 ? false : null" placeholder="Tanggal Efektif" type="date" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Komponen Gaji Pokok" label-for="Komponen Gaji Pokok">
              <validation-provider #default="{ errors }" name="Nama Panggilan" rules="required">
                <b-form-input id="component_name" v-model="dataStudent.component_name"
                  :state="errors.length > 0 ? false : null" placeholder="Komponen Gaji Pokok" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Pengali" label-for="multiplier">
              <validation-provider #default="{ errors }" name="Pengali" rules="required">
                <b-form-input id="multiplier" v-model="dataStudent.multiplier" :state="errors.length > 0 ? false : null"
                  placeholder="Pengali" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nominal" label-for="nominal">
              <validation-provider #default="{ errors }" name="nominal" rules="required">
                <b-form-input id="nominal" v-model="dataStudent.nominal" :state="errors.length > 0 ? false : null"
                  placeholder="Nominal" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Deskripsi" label-for="description">
              <validation-provider #default="{ errors }" name="Deskripsi" rules="required">
                <b-form-input id="description" v-model="dataStudent.description"
                  :state="errors.length > 0 ? false : null" placeholder="Deskripsi" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <!-- submit and reset -->
          <b-col>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mr-1"
              @click="() => $router.go(-1)">
              Back
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary">
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  // BContainer,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  // BFormCheckbox,
  BFormInvalidFeedback,
  BForm,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'

export default {
  name: 'DataSiswaEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // BContainer,
    // BFormCheckbox,
    BForm,
    BButton,
    vSelect,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  data: () => ({
    listPegawai: [],
    required,
    dataStudent: {
      employee_id: '',
      effective_date: '',
      component_name: '',
      multiplier: '',
      nominal: '',
      description: '',
    },

    dataIsActive: [
      { value: 1, text: 'Aktif' },
      { value: 0, text: 'Tidak Aktif' },
    ],
  }),
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
  },
  created() {
    this.getStudentDetail()
    this.getpegawai()
  },
  methods: {
    async getpegawai() {
      try {
        const response = await this.$http.get('/employees')
        this.listPegawai = response.data.data
        console.log(response.data.data);
      } catch (err) {
        this.showToast('danger', 'top-center', 'Get Presences Not Success')
        console.log(err)
      }
    },
    async getStudentDetail() {
      try {
        const studentId = this.$route.params.id
        const response = await this.$http.get(`/fixedsalaries/${studentId}`)
        this.fillStudentData(response.data.data)
      } catch (err) {
        console.log(err)
      }
    },
    fillStudentData(data) {
      this.dataStudent.employee_id = data.employee_id
      this.dataStudent.effective_date = data.effective_date.substr(0, 10)
      this.dataStudent.component_name = data.component_name
      this.dataStudent.multiplier = data.multiplier
      this.dataStudent.nominal = data.nominal
      this.dataStudent.description = data.description
    },
    validationForm() {
      this.$refs.validUpdate.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line
          try {
            const studentId = this.$route.params.id
            const dataFormStudent = new FormData()
            Object.keys(this.dataStudent).forEach(key => {
              dataFormStudent.append(key, this.dataStudent[key])
            })
            // Object.keys(this.locations).forEach(key => {
            //   dataFormStudent.append(key, this.locations[key])
            // })
            dataFormStudent.append('_method', 'PUT')
            await this.$http.post(`/fixedsalaries/${studentId}`, dataFormStudent, {
              headers: {
                'content-type': 'multipart/form-data',
              },
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'gaji-tetap' })
          } catch (err) {
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Data Tidak Berhasil',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            console.log(err)
          }
        }

        // function alertnya() {
        //   alert('form submitted!')
        // }
      })
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
